import React, { useEffect, useState } from "react";
import { Courousel } from "../Courousel";
import { Loader } from "../Loader";
import { FavoriteGirlAnimation } from "../FavoriteGirlAnimation";
import { Nav } from "./Nav";
import { LoaderContainer } from "./LoaderContainer";
import { girls } from "../../data";

export const SliderOffers = ({ selectNationality = "" }) => {
  const [loading, setLoading] = useState(true);
  const [currentCategory, setCurrentCategory] = useState(0);

  const nav = girls.map(({ nationality }) => nationality);
  const sliders =
    selectNationality === ""
      ? girls[currentCategory].girls
      : girls.find(g => g.nationality === selectNationality).girls;

  const handleChangeCategory = index => {
    setLoading(true);
    setCurrentCategory(index);
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [loading]);

  return (
    <div className="slider-offers">
      {selectNationality === "" && (
        <Nav nav={nav} currentCategory={currentCategory} onChange={handleChangeCategory} />
      )}
      {loading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <Courousel slides={sliders} />
      )}
      <FavoriteGirlAnimation />
    </div>
  );
};
