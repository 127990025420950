export const japan = [
  {
    name: "Himari",
    age: 29,
    location: "Japan, Nagoya",
  },
  {
    name: "Hina",
    age: 35,
    location: "Japan, Toyohashi",
  },
  {
    name: "Yua",
    age: 32,
    location: "Japan, Toyokawa",
  },
  {
    name: "Sakura",
    age: 22,
    location: "Japan, Ichinomiya",
  },
  {
    name: "Ichika",
    age: 33,
    location: "Japan, Seto",
  },
  {
    name: "Akari",
    age: 29,
    location: "Japan, Nishio",
  },
  {
    name: "Sara",
    age: 34,
    location: "Japan, Inazawa",
  },
  {
    name: "Yui",
    age: 19,
    location: "Japan, Chita",
  },
  {
    name: "Aoi",
    age: 18,
    location: "Japan, Aisai",
  },
  {
    name: "Niko",
    age: 27,
    location: "Japan, Nagakute",
  },
];
