export const china = [
  {
    name: "Zhi Ruo",
    age: 19,
    location: "China, Beijing",
  },
  {
    name: "Yu Yan",
    age: 35,
    location: "China, Macau",
  },
  {
    name: "Hui Yin",
    age: 18,
    location: "China, Ningguo",
  },
  {
    name: "Ying Yue",
    age: 20,
    location: "China, Tianjin",
  },
  {
    name: "Wang Shu",
    age: 21,
    location: "China, Anqing",
  },
  {
    name: "Feng Mian",
    age: 34,
    location: "China, Huainan",
  },
  {
    name: "Mo Chou",
    age: 22,
    location: "China, Shanghai",
  },
  {
    name: "Fei Hong",
    age: 33,
    location: "China, Chuzhou",
  },
  {
    name: "Nian Zhen",
    age: 29,
    location: "China, Mingguang",
  },
  {
    name: "Ru Shi",
    age: 27,
    location: "China, Wuwei",
  },
];
