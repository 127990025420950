import React from "react";

const Chinese = React.lazy(() => import("../pages/nationalities/chinese"));
const Thai = React.lazy(() => import("../pages/nationalities/thai"));
const Philipines = React.lazy(() => import("../pages/nationalities/philipines"));
const Vietnamese = React.lazy(() => import("../pages/nationalities/vietnamese"));
const PuertoRican = React.lazy(() => import("../pages/nationalities/puertorican"));
const Mexican = React.lazy(() => import("../pages/nationalities/mexican"));
const Brazilian = React.lazy(() => import("../pages/nationalities/brazilian"));
const Colombian = React.lazy(() => import("../pages/nationalities/colombian"));
const Dominican = React.lazy(() => import("../pages/nationalities/dominican"));
const Latvian = React.lazy(() => import("../pages/nationalities/latvian"));
const Belarus = React.lazy(() => import("../pages/nationalities/belarus"));
const Polish = React.lazy(() => import("../pages/nationalities/polish"));
const Russian = React.lazy(() => import("../pages/russian"));
const Ukrainian = React.lazy(() => import("../pages/ukrainian"));
const Asian = React.lazy(() => import("../pages/asian"));
const Latin = React.lazy(() => import("../pages/latin"));
const European = React.lazy(() => import("../pages/european"));

export const nationalities = [
  {
    name: "Chinese",
    title: "Where To Find Chinese Girlfriend?",
    review: "/nationalities/chinese",
    component: Chinese,
  },
  {
    name: "Thai",
    title: "Korean Ladies As Girlfriends",
    review: "/nationalities/thai",
    component: Thai,
  },
  {
    name: "Philipines",
    title: "Dating Philipines. Why And Where?",
    review: "/nationalities/philipines",
    component: Philipines,
  },
  {
    name: "Vietnamese",
    title: "What Is The Charm Of Vietnamese girls?",
    review: "/nationalities/vietnamese",
    component: Vietnamese,
  },
  {
    name: "PuertoRican",
    title: "What Should You Know Anout Puerto Rican Dating?",
    review: "/nationalities/puertorican",
    component: PuertoRican,
  },
  {
    name: "Mexican",
    title: "Why Mexican Girls Are So Fascinating?",
    review: "/nationalities/mexican",
    component: Mexican,
  },
  {
    name: "Brazilian",
    title: "Why Do We Think Brazillian Girls Such Unique?",
    review: "/nationalities/brazilian",
    component: Brazilian,
  },
  {
    name: "Colombian",
    title: "Colombian Women: Main Treasure of South America",
    review: "/nationalities/colombian",
    component: Colombian,
  },
  {
    name: "Dominican",
    title: "Dominican Singles: What To Expect Dating These Exotic Beauties?",
    review: "/nationalities/dominican",
    component: Dominican,
  },
  {
    name: "Latvian",
    title: "Why Do Latvian Ladies Are So Special?",
    review: "/nationalities/latvian",
    component: Latvian,
  },
  {
    name: "Belarus",
    title: "Stunning Beauty And Courage: Belarus Woman To Date",
    review: "/nationalities/belarus",
    component: Belarus,
  },
  {
    name: "Polish",
    title: "Polish Girls And Women: Guide How To Date",
    review: "/nationalities/polish",
    component: Polish,
  },
  // {
  //   name: "Russian",
  //   title: "Connect with Russian women for chatting",
  //   review: "/russian",
  //   component: Russian,
  // },
  // {
  //   name: "Ukrainian",
  //   title: "Join the sites to find your incredible Ukrainian lady",
  //   review: "/ukrainian",
  //   component: Ukrainian,
  // },
  // {
  //   name: "Asian",
  //   title: "Where To Find asian Girlfriend?",
  //   review: "/asian",
  //   component: Asian,
  // },
  // {
  //   name: "Latin",
  //   title: "What To Expect Dating These Exotic Beauties?",
  //   review: "/latin",
  //   component: Latin,
  // },
  // {
  //   name: "European",
  //   title: "What Is The Charm Of European girls?",
  //   review: "/european",
  //   component: European,
  // },
];
