export const russian = [
  {
    name: "Olga",
    age: 28,
    location: "Russia, Moscow",
  },
  {
    name: "Anastasia",
    age: 22,
    location: "Russia, Saint Petersburg",
  },
  {
    name: "Svetlana",
    age: 35,
    location: "Russia, Novosibirsk",
  },
  {
    name: "Tatiana",
    age: 30,
    location: "Russia, Yekaterinburg",
  },
  {
    name: "Maria",
    age: 27,
    location: "Russia, Kazan",
  },
  {
    name: "Elena",
    age: 33,
    location: "Russia, Nizhny Novgorod",
  },
  {
    name: "Irina",
    age: 24,
    location: "Russia, Samara",
  },
  {
    name: "Natalia",
    age: 31,
    location: "Russia, Omsk",
  },
  {
    name: "Yulia",
    age: 29,
    location: "Russia, Rostov-on-Don",
  },
  {
    name: "Ekaterina",
    age: 26,
    location: "Russia, Chelyabinsk",
  },
];
