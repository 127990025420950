export const slavic = [
  {
    name: "Agata",
    age: 29,
    location: "Czech Republic, Praha",
  },
  {
    name: "Emina",
    age: 19,
    location: "Bosnia, Zvornik",
  },
  {
    name: "Kasia",
    age: 36,
    location: "Poland, Łódź",
  },
  {
    name: "Malina",
    age: 35,
    location: "Serbia, Belgrade",
  },
  {
    name: "Nina",
    age: 20,
    location: "Slovakia, Bratislava",
  },
  {
    name: "Lara",
    age: 34,
    location: "Slovenia, Ljubljana",
  },
  {
    name: "Rosa",
    age: 19,
    location: "Montenegro, Podgorica",
  },
  {
    name: "Darya",
    age: 34,
    location: "Belarus, Minsk",
  },
  {
    name: "Nikol",
    age: 38,
    location: "Bulgaria, Sofia",
  },
  {
    name: "Ksenia",
    age: 37,
    location: "Russia, Khabarovsk",
  },
];
