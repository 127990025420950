// App.js
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Switch } from "react-router-dom";

import { Routes } from "./Routes";
import { Loader, ScrollToTop } from "./components";

import { store } from "./store";

function App() {
  React.useEffect(() => {
    if (localStorage.getItem("search") === null) {
      localStorage.setItem("search", window.location.search);
    }
  }, []);

  return (
    <div className="App">
      <Provider store={store}>
        <BrowserRouter>
          <ScrollToTop>
            <React.Suspense fallback={<Loader loading />}>
              <Switch>
                <Routes />
              </Switch>
            </React.Suspense>
          </ScrollToTop>
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
