import React from "react";
import { useLocation } from "react-router-dom";

export const TrackingLink = ({
  children,
  href = "/",
  className = "",
  component: Component = null,
}) => {
  const queryParams = new URLSearchParams(useLocation().search);
  const offer = href;

  const generateLink = () => {
    let link = `/tooff.php?offer=${offer}`;

    if (queryParams.toString()) {
      // If there are existing query parameters, append them with &
      link += `&${queryParams.toString()}`;
    }

    return link;
  };

  const link = generateLink();

  if (Component === null) {
    return (
      <a href={link} className={className} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  }

  return (
    <Component href={link} className={className} target="_blank" rel="noopener noreferrer">
      {children}
    </Component>
  );
};
