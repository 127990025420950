export const philippine = [
  {
    name: "Angel",
    age: 25,
    location: "Philippines, Talisay",
  },
  {
    name: "Princess",
    age: 19,
    location: "Philippines, Manila",
  },
  {
    name: "Mary",
    age: 20,
    location: "Philippines, Abucay",
  },
  {
    name: "Jessa",
    age: 27,
    location: "Philippines, Lapu-Lapu",
  },
  {
    name: "Nicole",
    age: 29,
    location: "Philippines, Butuan City",
  },
  {
    name: "Samantha",
    age: 21,
    location: "Philippines, Davao City",
  },
  {
    name: "Jasmine",
    age: 29,
    location: "Philippines, Panay City",
  },
  {
    name: "Rosa",
    age: 32,
    location: "Philippines, Tacloban City",
  },
];
