export const navsHeader = [
  // {
  //   name: "Oriental",
  //   url: "/oriental",
  // },
  {
    name: "Ukrainian",
    url: "/ukrainian",
  },
  {
    name: "Russian",
    url: "/russian",
  },
  {
    name: "Asian",
    url: "/asian",
  },
  {
    name: "Latin",
    url: "/latin",
  },
  {
    name: "European",
    url: "/european",
  },
];
